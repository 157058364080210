<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">{{ vehicle.license_plate }}</router-link></li>
              <li>Schäden</li>
            </ul>
            <div class="header-actions">
              <p><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages/new'" class="button button-orange button-small">Schaden hinzufügen ›</router-link></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/reservations'">Reservierungen</router-link></li>
            <li class="active"><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
            <!--<li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/insurance'">Versicherung</router-link></li>-->
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
          </ul>

          <div v-if="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>

            <div class="admin-body">
              <div v-if="vehicle_damages.length == 0">
                <p>Noch keine Schäden.</p>
              </div>
              <div v-else>

                <table class="table">
                  <thead>
                    <tr>
                      <th width="5%"></th>
                      <th width="15%">Datum</th>
                      <th width="20%">Beschreibung</th>
                      <th width="20%">Verursacher</th>
                      <th width="10%">Betrag</th>
                      <th width="30%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="damage in vehicle_damages" v-bind:key="damage.id">
                      <td>
                        <span v-if="damage.status == 'enabled'" class="status status-green"></span>
                        <span v-if="damage.status == 'disabled'" class="status status-red"></span>
                      </td>
                      <td>{{ damage.created_at_formatted }}</td>
                      <td>{{ damage.description }}</td>
                      <td><span v-if="damage.user">{{ damage.user.first_name }} {{ damage.user.last_name }}</span></td>
                      <td>{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(damage.damage_sum) }}</td>
                      <td style="text-align:right">
                        <router-link :to="'/admin/vehicles/'+vehicle.id+'/damages/'+damage.id" class="material-icons" style="display:inline-block;vertical-align:middle;margin-right:10px">edit</router-link>
                        <a @click="delete_damage(damage)" style="margin-right:10px;cursor:pointer;display:inline-block;vertical-align:middle;"><span class="material-icons" style="margin-right:0">delete</span></a>
                        <span v-if="damage.invoices.length == 0" style="display:inline-block;"><a @click="create_invoice(damage)" style="padding-left:20px;margin-left:10px;border-left:1px solid #f1f1f1;cursor:pointer;"><span class="material-icons" style="display:inline-block;vertical-align:middle;margin-right:5px;">receipt</span><span style="display:inline-block;vertical-align:middle;">Erstellen ›</span></a></span>
                        <span v-else style="display:inline-block;"><router-link :to="'/invoices/'+damage.invoices[0].uuid" style="padding-left:20px;margin-left:10px;border-left:1px solid #f1f1f1;cursor:pointer;"><span class="material-icons" style="display:inline-block;vertical-align:middle;margin-right:5px;">receipt</span><span style="display:inline-block;vertical-align:middle;">Öffnen ›</span></router-link></span>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'admin_vehicle_repairs',
  components: {
    AdminNav
  },
  data () {
    return {
      loading: true,
      errors: [],
      vehicle: {},
      vehicle_damages: []
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle = response.data.vehicle;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/damages', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_damages = response.data.vehicle_damages;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })

    },
    create_invoice(damage) {

      var items = [
        {
          "name": "Kostenvoranschlag",
          "price": damage.amount_estimate
        },
        {
          "name": "Kaution",
          "price": damage.amount_deposit
        },
        {
          "name": damage.description,
          "price": damage.damage_sum
        }
      ]

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/invoices', {
        'damage_id': damage.id,
        'date': new Date(),
        'company': damage.user.company,
        'first_name': damage.user.first_name,
        'last_name': damage.user.last_name,
        'street_name': damage.user.street_name,
        'street_number': damage.user.street_number,
        'zip_code': damage.user.zip_code,
        'city': damage.user.city,
        'tax_rate': 0,
        'sum_net': damage.damage_sum,
        'sum_tax': damage.damage_sum,
        'sum_gross': damage.damage_sum,
        'items': items
      }, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.'
        });
        this.get_data();
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: 'Fehler',
            text: val
          });
        })
      })

    },
    delete_damage(damage) {
      if(confirm("Wirklich löschen?")) {
        axios.delete(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.vehicle.id+'/damages/'+damage.id, { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          console.log(response);
          this.$notify({
            group: 'alert',
            type: 'success',
            title: 'Erfolgreich gespeichert.'
          });
          this.get_data();
        })
      }
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
